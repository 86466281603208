export enum Environment {
  prod = 'prod',
  stage = 'stage',
  dev = 'dev',
}

export interface Auth0Config {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
  scope: string;
}

export interface VideoAskConfig {
  moments: string;
  contact: string;
  feedback: string;
  coaching: string;
  community: string;
  curator: string;
  expFeedback: string;
}

export interface Links {
  coachesCorner: string;
}

export interface Config {
  baseUrl: string;
  apiEndpoint: string;
  apiWsEndpoint: string;
  comingSoon?: boolean;
  segmentKey?: string;
  logRocketId?: string;
  auth0: Auth0Config;
  videoask: VideoAskConfig;
  helloLog?: boolean;
  algoliaApplicationId?: string;
  algoliaSearchKey?: string;
  algoliaIndexPrefix?: string;
  googleApiKey?: string;
  apolloLogErrors?: boolean;
  links: Links;
  calendly: {
    link?: string;
    welcome?: string;
  };
  sanity: {
    projectId: string;
    dataset: string;
  };
}
