import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import { usePathname } from 'next/navigation';
import { createContext, useContext } from 'react';
import { CurrentUserQuery } from '../../../generated/graphql';
import { useCurrentUser } from '../../../hooks/use-current-user/use-current-user';
import { VIDEO_ASK_API_BASE_URL, VIDEO_ASK_SHARE_BASE_URL, VideoAskEventType } from '../constants';

declare global {
  interface Window {
    videoask: {
      loadModal: (opts: unknown, callback?: unknown) => void;
      loadEmbed: (opts: unknown, callback?: unknown) => void;
    };
  }
}

export const videoAskClient = axios.create({
  baseURL: `${VIDEO_ASK_API_BASE_URL}/`,
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter!)),
});

export const getVideoAskFormData = (url: string) => {
  const videoId = url.replace(`${VIDEO_ASK_SHARE_BASE_URL}/`, '');
  return videoAskClient.get(`forms/sharing/${videoId}`).then(res => res.data);
};

type VideoAskModalOptions = {
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  modalType?: 'Fullscreen' | 'Side';
};

const defaultModalOptions = {
  position: 'bottom-right',
};

type VideoAskContextType = {
  modal?: { remove?: () => void };
  url?: string;
};

export const VideoAskContext = createContext<VideoAskContextType>({});

const getFullName = (user: CurrentUserQuery['me']) => {
  return `${user.firstName || ''} ${user.lastName || ''}`.trim();
};

const contactFields = {
  contact_name: getFullName,
  contact_email: 'email',
  contact_phone_number: 'phone',
} as const;

type ContactFields = typeof contactFields;
type ContactFieldKey = keyof ContactFields;

const stringifyVideoAskContactVars = (
  user?: CurrentUserQuery['me'],
  variables?: Record<string, string>,
) => {
  // add user variables
  const userFields = user
    ? Object.keys(contactFields).reduce((fieldsAcc: string[], key) => {
        const userFieldOrFunc = contactFields[key as ContactFieldKey];
        const value =
          typeof userFieldOrFunc === 'function' ? userFieldOrFunc(user) : user[userFieldOrFunc];
        return value ? [...fieldsAcc, `${key}=${encodeURIComponent(value)}`] : fieldsAcc;
      }, [])
    : [];

  // add custom variables
  const fields = variables
    ? Object.keys(variables).reduce((fieldsAcc, key) => {
        const value = variables[key];
        return value ? [...fieldsAcc, `${key}=${encodeURIComponent(value)}`] : fieldsAcc;
      }, userFields)
    : userFields;

  if (!fields || !fields.length) {
    return '';
  }

  return `#${fields.join('&')}`;
};

export const useVideoAskModal = (
  url: string,
  variables = {},
  options: VideoAskModalOptions = {},
) => {
  const router = {
    pathname: usePathname() || '',
  };
  const isMobile = useMediaQuery('(max-width:960px)');
  const opts: VideoAskModalOptions = Object.assign(
    {
      modalType: isMobile ? 'Fullscreen' : 'Side',
    },
    defaultModalOptions,
    options,
  );
  const videoAskContext = useContext(VideoAskContext);
  const { data: user } = useCurrentUser();
  const urlVars = stringifyVideoAskContactVars(user, {
    ...variables,
    page: router.pathname,
  });

  const close = () => {
    if (videoAskContext.modal && typeof videoAskContext.modal.remove === 'function') {
      videoAskContext.modal.remove();
    }
    videoAskContext.modal = undefined;
    videoAskContext.url = undefined;
  };

  const open = () => {
    if (
      videoAskContext.url === url ||
      !window.videoask ||
      typeof window.videoask.loadModal !== 'function'
    ) {
      return;
    }

    // close previously open modal
    if (videoAskContext.modal && typeof videoAskContext.modal.remove === 'function') {
      videoAskContext.modal.remove();
    }
    videoAskContext.modal = undefined;

    // set current video ask url
    videoAskContext.url = url;

    window.videoask.loadModal(
      {
        url: `${url}${urlVars}`,
        options: opts,
      },
      {
        onLoadModal: ({ element }: any) => {
          videoAskContext.modal = element;
        },
        onCloseModal: () => {
          videoAskContext.modal = undefined;
          videoAskContext.url = undefined;
        },
        onMessage: ({ type }: any) => {
          if (type === VideoAskEventType.Submitted) {
            setTimeout(close, 4000);
          }
        },
      },
    );
  };

  return [open, close];
};
