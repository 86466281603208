import { Config } from './types';

const apiEndpoint = process.env.NEXT_PUBLIC_API_ENDPOINT || 'http://localhost:8081/graphql';
export const devConfig: Config = {
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000',
  apiEndpoint,
  apiWsEndpoint: apiEndpoint.replace('http', 'ws'),
  segmentKey: 'DOwyGpNZO3V8qBg3QVhtMRitOhm5E5eB',
  auth0: {
    redirectUri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI || 'http://localhost:3000/auth/',
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN || 'curikodev.us.auth0.com',
    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || 'Vaw3Jfhs21FRQSnjK0hXuFhPM920Qrbb',
    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || 'https://api.curiko.test',
    scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE || 'read:current_user',
  },
  videoask: {
    moments: 'https://www.videoask.com/folq1zmsz',
    coaching: 'https://www.videoask.com/fw160wjsb',
    community: 'https://www.videoask.com/fkfs2j5w4',
    curator: 'https://www.videoask.com/frxch2c7t',
    contact: 'https://www.videoask.com/f1gz8x65v',
    feedback: 'https://www.videoask.com/fshkjiarl',
    expFeedback: 'https://www.videoask.com/f8nst395k',
  },
  links: {
    coachesCorner: 'https://www.curiko.ca/experiences/c90b66d0-bda5-4b14-8cb6-fcb0025bcc6a/',
  },
  algoliaApplicationId: '6NM5PIJBR6',
  algoliaSearchKey: '8b4c4081c8cdaa54cc1f4af814a82ca0',
  algoliaIndexPrefix: 'dev_',
  googleApiKey: 'AIzaSyCsnpC9kWNKfXp_NEVqFz0u2OAYVlFXAUM',
  apolloLogErrors: true,
  calendly: {
    link: 'https://calendly.com/swarnjit',
    welcome: 'https://calendly.com/sarahk-99/host-welcome-sarahk',
  },
  sanity: {
    projectId: 'x5d0gjiz',
    dataset: 'production',
  },
};
