import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { useAuth0 } from '../../../../auth';

const LoginButton = styled(Button)`
  border-radius: 18px;
  border: 2px solid ${({ theme }) => theme.palette.common.yellow};
  color: ${({ theme }) => theme.palette.common.yellow};
  background-color: transparent;
  text-transform: none;
  font-family: var(--font-work-sans), sans-serif;
  font-weight: 600;
  font-size: 17px;
  height: 36px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 10px;
  :hover {
    background-color: #ffcf31;
    color: #251628;
  }
`;

const SignupButton = styled(Button)`
  border-radius: 18px;
  color: ${({ theme }) => theme.palette.common.midnight};
  background-color: ${({ theme }) => theme.palette.common.cream};
  text-transform: none;
  font-family: var(--font-work-sans), sans-serif;
  font-weight: 600;
  font-size: 17px;
  height: 36px;
  padding-left: 2rem;
  padding-right: 2rem;

  :hover {
    background-color: #f0926e;
  }
`;

const AnonymousMenu = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <LoginButton onClick={() => loginWithRedirect()}>Login</LoginButton>
      <SignupButton
        onClick={() =>
          loginWithRedirect({
            screen_hint: 'signup',
          })
        }
      >
        Signup
      </SignupButton>
    </>
  );
};
export { AnonymousMenu };
