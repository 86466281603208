import { useAtom } from 'jotai';
import { changeTopNavColourAtom } from '../use-change-top-nav-colour-atom/use-change-top-nav-colour-atom';
export function useChangeTopNavColour() {
  const [changeTopNavColour, setChangeShowTopNavColour] = useAtom(changeTopNavColourAtom);

  return {
    changeTopNavColour,
    setChangeShowTopNavColour,
  };
}
