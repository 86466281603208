import { Config } from './types';

export const prodConfig: Config = {
  baseUrl: 'https://www.curiko.ca',
  apiEndpoint: process.env.NEXT_PUBLIC_API_ENDPOINT || 'https://api.curiko-staging.ca/graphql',
  apiWsEndpoint: process.env.NEXT_PUBLIC_API_ENDPOINT || 'wss://api.curiko-staging.ca/graphql',
  segmentKey: 'mchJsbrXUTIr9RBmEv7f0eWrcYBN1GuR',
  auth0: {
    redirectUri: 'https://www.curiko.ca/auth/',
    domain: 'curiko.us.auth0.com',
    clientId: '172q9RnlQLvxJjqasBC2SbR5HuX7Fjmp',
    audience: 'https://api.curiko.ca',
    scope: 'read:current_user',
  },
  videoask: {
    moments: 'https://www.videoask.com/folq1zmsz',
    coaching: 'https://www.videoask.com/fw160wjsb',
    community: 'https://www.videoask.com/fkfs2j5w4',
    curator: 'https://www.videoask.com/frxch2c7t',
    contact: 'https://www.videoask.com/f1gz8x65v',
    feedback: 'https://www.videoask.com/fshkjiarl',
    expFeedback: 'https://www.videoask.com/f8nst395k',
  },
  links: {
    coachesCorner: 'https://www.curiko.ca/experiences/c90b66d0-bda5-4b14-8cb6-fcb0025bcc6a/',
  },
  helloLog: true,
  logRocketId: 'bruyyy/curiko',
  algoliaApplicationId: '6NM5PIJBR6',
  algoliaSearchKey: 'ad5b4fa28faae0fe0093ce1081689f24',
  algoliaIndexPrefix: 'prod_',
  googleApiKey: 'AIzaSyD760wqVUK0Mtx7o_tD7B4-3s6JpIEDBEo',
  calendly: {
    link: 'https://calendly.com/sarahk-99/host-welcome-sarahk',
    welcome: 'https://www.videoask.com/fymsp1t3m',
  },
  sanity: {
    projectId: 'x5d0gjiz',
    dataset: 'production',
  },
};
