import BottomNavbar from '@/components/navbars/bottom-nav/bottom-nav';
import TopNavbar from '@/components/navbars/top-navbar/top-navbar';
import { Toolbar, useMediaQuery } from '@mui/material';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import Footer from '../../footer/footer';
import { Globals } from './components/globals/globals';

export interface PrimaryLayoutProps {
  children: ReactNode;
}

export const PRIMARY_LAYOUT_CONTENT_ID = 'content';

const PrimaryLayout = ({ children }: PrimaryLayoutProps) => {
  const isMobile = useMediaQuery('(max-width:1080px)');
  const pathname = usePathname() || '';
  const currentPath = pathname.split('/')[1]?.split('/')[0];
  return (
    <div>
      <Globals />
      <TopNavbar />
      <Toolbar />
      <div id={PRIMARY_LAYOUT_CONTENT_ID}>{children}</div>
      {isMobile ? <BottomNavbar /> : currentPath === 'chat' ? null : <Footer />}
    </div>
  );
};

export default PrimaryLayout;
