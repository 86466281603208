import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { forwardRef } from 'react';
import { CurrentUserFieldsFragment } from '../../../../generated/graphql';
import { Link } from '../../../link/link';
import { Avatar } from '../avatar/avatar';

const Content = styled.span`
  display: block;
  padding-left: 15px;
`;

const Title = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 16px;
  color: #231727;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 4px;
`;

const Subtitle = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 14px;
  color: #6c6c6c;
  line-height: 1;
`;

const AvatarContainer = styled.span`
  display: block;
  width: 60px;
  height: 60px;
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
  display: block;
  padding: 20px 45px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  &::after {
    content: '';
    background: #e0e0e0;
    display: block;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 30px;
    right: 30px;
  }
`;

export type UserMenuHeaderProps = {
  user?: CurrentUserFieldsFragment;
  onClick?: () => void;
};

const UserMenuHeader = forwardRef<HTMLDivElement, UserMenuHeaderProps>(
  ({ user, onClick }, ref): React.ReactElement => {
    const profile = user?.profile;
    const id = user?.id;
    if (!id) {
      return <></>;
    }
    return (
      <div ref={ref}>
        <StyledLink href={`/profile/${id}/`} onClick={onClick}>
          <AvatarContainer>
            <Avatar bg profile={profile || undefined} />
          </AvatarContainer>
          <Content>
            <Title>{profile?.nickname}</Title>
            <Subtitle>See your profile</Subtitle>
          </Content>
        </StyledLink>
      </div>
    );
  },
);

UserMenuHeader.displayName = 'UserMenuHeader';

export { UserMenuHeader };
