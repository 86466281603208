import styled from '@emotion/styled';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { usePathname, useRouter } from 'next/navigation';
import React, { useCallback } from 'react';
import { ChatMenu } from '@/components/chat-menu/chat-menu';
import useHideFooterAtom from '@/components/footer/hooks/use-hide-footer-atom';
import { useAuth0 } from '../../../auth';
import { NotificationsMenu } from '../../notifications-menu/notifications-menu';
import { UserMenu } from '../../user-menu/user-menu';
import AlertIcon from './svgs/alert.svg';
import ChatIcon from './svgs/chat.svg';
import ExploreIcon from './svgs/explore.svg';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
`;

const BottomNavbar = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const router = useRouter();
  const asPath = usePathname();

  const handleChange = useCallback(
    (event: any, value: string) => {
      if (value === 'explore' || value === 'chat') {
        router.push(`/${value}/`);
      }
    },
    [router],
  );
  const { hideFooter } = useHideFooterAtom();
  if (hideFooter) {
    return null;
  }
  if (!isAuthenticated || isLoading) {
    return null;
  }
  const value = asPath.split('/')[1] || '';

  return (
    <Container>
      <BottomNavigation value={value} showLabels onChange={handleChange}>
        <BottomNavigationAction value="explore" label="Explore" icon={<ExploreIcon />} />
        <ChatMenu bottomNav value="chat" label="Chat" icon={<ChatIcon />} />
        <NotificationsMenu
          bottomNav
          value="notifications"
          label="Notifications"
          icon={<AlertIcon />}
        />
        <UserMenu bottomNav value="profile" label="You" />
      </BottomNavigation>
    </Container>
  );
};

export default BottomNavbar;
