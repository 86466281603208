'use client';
import { useEffect } from 'react';
import { useAuth0 } from '../../auth';
import { useCurrentUserQuery } from '../../generated/graphql';
import { nullToUndefined } from '../../utils/nullToUndefined';

export const useCurrentUser = () => {
  const { isAuthenticated: authIsAuthenticated, isLoading: authIsLoading, logout } = useAuth0();
  const skip = !authIsAuthenticated || authIsLoading;
  const {
    data,
    loading: queryIsLoading,
    error,
  } = useCurrentUserQuery({
    skip,
  });

  const loading = authIsLoading || queryIsLoading;

  // if authenticated, but user is empty, then we tell auth0 to delete the current cache
  useEffect(() => {
    if (authIsAuthenticated && !loading && !data) {
      logout({ localOnly: true });
    }
  }, [loading, data, authIsAuthenticated, logout]);

  return {
    data: nullToUndefined(data?.me),
    loading,
    error,
  };
};
