import React from 'react';
type NotiProps = {
  color: string;
};
const NotificationIcon = ({ color }: NotiProps) => {
  return (
    <svg width="31" height="38" viewBox="0 0 31 38" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M28.764 25.9229C27.7043 23.7716 27.138 21.3414 27.1191 18.8643C27.0249 15.1312 27.2635 11.2962 25.0787 8.23987C23.9543 6.81335 22.635 5.61112 21.1736 4.68142C20.1084 3.97006 19.003 3.34282 17.865 2.80396C17.9278 -0.645329 13.9411 -0.73993 13.759 2.9495C11.9869 3.42783 10.3274 4.35257 8.8984 5.65802C7.46938 6.96348 6.30589 8.61764 5.49056 10.503C3.88333 14.7673 5.89865 20.1232 3.60709 24.1037C2.83487 25.3917 1.25903 26.7744 0.832111 28.3098C-0.423536 32.8943 7.0664 30.5802 9.72837 30.9222C9.86707 32.1483 10.3024 33.3038 10.984 34.2551C12.9993 37.1004 17.1932 38.3011 19.648 35.9579C20.7723 34.7655 21.4747 33.1318 21.6194 31.3734C24.3692 31.4826 30.6914 31.3734 30.6914 30.049C30.6789 28.499 29.4106 27.1673 28.764 25.9229ZM6.40719 28.1788C6.02421 28.1788 5.64124 28.2225 5.24571 28.1788C5.09513 28.2025 4.94185 28.1852 4.79802 28.1284C4.65418 28.0716 4.52373 27.9767 4.41698 27.8513C3.95867 27.1237 5.21432 25.3553 5.61613 24.8241C6.30299 24.1097 6.89157 23.2778 7.36148 22.3572C7.67385 21.4088 7.80867 20.3943 7.75701 19.3809C7.68167 15.5023 7.39287 9.25137 11.2603 7.534C11.3304 7.49306 11.4078 7.47173 11.4863 7.47173C11.5648 7.47173 11.6422 7.49306 11.7123 7.534C11.9383 7.70865 11.8065 8.11616 11.6558 8.38541C11.3985 8.85388 11.1658 9.33989 10.9589 9.84081C9.97434 12.5855 9.51387 15.5446 9.60909 18.515C9.60909 20.0286 9.65303 21.5422 9.74093 23.0558C9.7243 23.9935 9.95423 24.9148 10.4001 25.6974C11.2604 26.8086 12.3364 27.6667 13.5393 28.2006C10.7706 28.0842 7.81351 28.1206 6.40719 28.1788Z" />
    </svg>
  );
};

export default NotificationIcon;
