import styled from '@emotion/styled';
import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import Image, { ImageProps } from 'next/image';
import { useCallback, useEffect } from 'react';
import { YellowButton } from '@/components/button/button';
import { CloseIcon } from '@/components/icons/icons';
import { nextImageTransitionProps } from '@/constants/next-image-transition-props';
import { useCurrentUser } from '@/hooks/use-current-user/use-current-user';
import useIsMobile from '@/hooks/use-is-mobile';
import Balloon from './assets/Balloon.png';
import Momenteer from './assets/CuriousAboutCurikoMomenteer.png';
import Hat from './assets/Hat.png';
import { useOnsiteTimer } from './hooks/use-onsite-timer/use-onsite-timer';
import { useWelcomeModalOpen } from './hooks/use-welcome-modal-open/use-welcome-modal-open';
import { config } from '@/config/environment';

// imageprops without the src, alt, width, and height props
type LimitedProps = Omit<ImageProps, 'src' | 'alt' | 'width' | 'height'>;

const StyledBaloon = styled((props: LimitedProps) => (
  <Image {...props} src={Balloon} alt="" width={142 / 2} height={360 / 2} />
))`
  position: absolute;
  top: 120px;
  right: 0;
  z-index: 1;
`;

const StyledHat = styled((props: LimitedProps) => (
  <Image {...props} src={Hat} alt="" width={132 / 2} height={218 / 2} />
))`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const PREVIOUSLY_CLOSED_KEY = 'welcomeModalClosed';

export function WelcomeModal() {
  const { welcomeModalOpen, setWelcomeModalOpen } = useWelcomeModalOpen();
  const { isMobile } = useIsMobile();
  const currentUser = useCurrentUser();
  const loggedIn = !!currentUser?.data?.id;
  const vettedIsNull = currentUser?.data?.welcomeVetted === undefined;
  const { onsiteTimer } = useOnsiteTimer();
  const lastTimeClosed =
    typeof window !== 'undefined' && window.localStorage.getItem(PREVIOUSLY_CLOSED_KEY);
  const notPreviousClosed = !lastTimeClosed;
  const twoWeeksSinceLastClosed =
    !!lastTimeClosed && Date.now() - Number(lastTimeClosed) > 1000 * 60 * 60 * 24 * 14;

  useEffect(() => {
    if (loggedIn && vettedIsNull && onsiteTimer && (notPreviousClosed || twoWeeksSinceLastClosed)) {
      setWelcomeModalOpen(true);
    }
  }, [
    loggedIn,
    vettedIsNull,
    onsiteTimer,
    notPreviousClosed,
    setWelcomeModalOpen,
    twoWeeksSinceLastClosed,
  ]);

  const handleClose = useCallback(() => {
    setWelcomeModalOpen(false);
    window.localStorage.setItem(PREVIOUSLY_CLOSED_KEY, `${Date.now()}`);
  }, [setWelcomeModalOpen]);

  return (
    <Dialog
      open={welcomeModalOpen}
      onClose={handleClose}
      fullWidth
      maxWidth={'lg'}
      scroll="body"
      PaperProps={{
        style: {
          overflow: 'hidden',
          position: 'relative',
        },
      }}
    >
      <IconButton
        style={{ position: 'absolute', top: 10, right: 10, zIndex: 99 }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      {isMobile && (
        <>
          <StyledBaloon />
          <StyledHat />
        </>
      )}
      <Box px={4} style={{ position: 'relative', zIndex: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box
              pb={4}
              pt={isMobile ? 8 : 2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 26,
                height: '100%',
                width: '100%',
                maxWidth: 320,
              }}
            >
              <Typography variant="h1" style={{ fontSize: isMobile ? 60 : 90 }}>
                Curious about Curiko?
              </Typography>
              <Typography style={{ fontSize: isMobile ? 18 : 20 }}>
                Meet with a team member and unlock access to a world of in-person experiences!
              </Typography>
              <div>
                <YellowButton
                  fullWidth={false}
                  onClick={() => {
                    handleClose();
                    window.open(config.calendly.welcome, '_blank');
                  }}
                >
                  Schedule a welcome
                </YellowButton>
              </div>
            </Box>
          </Grid>
          {!isMobile && (
            <Grid item xs={12} sm={6}>
              <Image {...nextImageTransitionProps} src={Momenteer} alt=""></Image>
            </Grid>
          )}
        </Grid>
      </Box>
    </Dialog>
  );
}
