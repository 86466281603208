import { theme } from '@/app/common/providers/theme-provider/theme';
import styled from '@emotion/styled';
import {
  Badge,
  BottomNavigationAction,
  BottomNavigationActionProps,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';
import {
  CreatedNotificationDocument,
  CreatedNotificationSubscription,
  NotificationStatus,
  UnreadNotificationsCountDocument,
  useMarkNotificationAsReadMutation,
  useNotificationsQuery,
  useUnreadNotificationsCountQuery,
} from '../../generated/graphql';
import { useCurrentUser } from '../../hooks/use-current-user/use-current-user';
import { toRelative } from '../../utils/toRelative';
import { Link } from '../link/link';
import { MenuHeader } from './components/menu-header/menu-header';
import NotificationIcon from './components/notification-icon/notification-icon';
import AlertSvg from './svgs/alert.svg';

const StyledMenuItemBase = styled(MenuItem)`
  width: 100%;
  justify-content: space-between;
  align-items: start;
  white-space: normal;
  padding: 20px 25px;
  position: relative;
`;

const StyledMenuItem = styled(StyledMenuItemBase)`
  &::after {
    content: '';
    background: #e0e0e0;
    display: block;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 25px;
    right: 25px;
  }
`;

const StyledMenuItemCenter = styled(StyledMenuItemBase)`
  display: block;
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
`;

const TextContainer = styled(Box)`
  width: 70%;
`;

const Title = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 16px;
  color: #231727;
  font-weight: 700;
`;

const Text = styled(Typography)`
  text-overflow: ellipsis;
  font-family: var(--font-work-sans), sans-serif;
  font-size: 16px;
  color: #231727;
  font-weight: normal;
`;

const DateContainer = styled(Box)`
  width: 30%;
  text-align: right;
`;

const DateBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 50%;
    left: -12px;
  }
`;

const DateText = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 12px;
  color: #231727;
`;

const LoadMore = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 16px;
  color: #231727;
  font-weight: 700;
  text-align: center;
`;

const MenuPaper = styled(Paper)`
  width: 340px;
  border-radius: 20px;

  ${theme.breakpoints.up('sm')} {
    width: 400px;
  }
`;

export const ISO_TIME_FORMAT = `yyyy-MM-dd'T'HH:mm:ss'Z'`;

type NotificationsMenuProps = BottomNavigationActionProps & {
  bottomNav?: boolean;
  icon?: React.ReactNode;
};

export const NotificationsMenuWithRef = (
  { bottomNav, icon, ...otherProps }: NotificationsMenuProps,
  ref: ForwardedRef<HTMLButtonElement>,
): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fillColor, setFillColor] = useState<string>('#FFF3DC');
  const { data: user } = useCurrentUser();
  const userId = user?.id;

  const { data: unreadData, subscribeToMore: subscribeToMoreUnread } =
    useUnreadNotificationsCountQuery({
      skip: !userId,
    });

  const {
    data: notificationsData,
    subscribeToMore: subscribeToMoreNotifications,
    fetchMore,
  } = useNotificationsQuery({
    skip: !userId,
  });

  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();

  const count = unreadData?.notificationAggregate[0]?.count?.id || 0;
  const endCursor = notificationsData?.notifications?.pageInfo?.endCursor || null;
  const hasNextPage = notificationsData?.notifications?.pageInfo?.hasNextPage;
  const nodes = (notificationsData?.notifications?.edges || []).map(edge => edge.node);

  useEffect(() => {
    if (!userId) {
      return;
    }
    //TODO: Fix types
    subscribeToMoreNotifications<CreatedNotificationSubscription>({
      document: CreatedNotificationDocument,
      updateQuery: (prev, { subscriptionData }): any => {
        const createdNotification = subscriptionData?.data?.createdNotification;
        if (!createdNotification || createdNotification.status !== NotificationStatus.Unread) {
          return prev;
        }
        return {
          ...prev,
          notifications: {
            ...prev.notifications,
            edges: [
              {
                node: createdNotification,
                cursor: null,
              },
              ...prev.notifications.edges,
            ],
          },
        };
      },
    });
    subscribeToMoreUnread<CreatedNotificationSubscription>({
      document: CreatedNotificationDocument,
      updateQuery: (prev, { subscriptionData }) => {
        const createdNotification = subscriptionData?.data?.createdNotification;
        if (!createdNotification) {
          return prev;
        }
        const count = prev?.notificationAggregate[0]?.count?.id || 0;
        return {
          ...prev,
          notificationAggregate: [
            {
              ...prev.notificationAggregate[0],
              count: {
                ...prev.notificationAggregate[0]?.count,
                id: count + 1,
              },
            },
          ],
        };
      },
    });
  }, [subscribeToMoreUnread, subscribeToMoreNotifications, userId]);

  const handleLoadMore = useCallback(() => {
    if (hasNextPage) {
      fetchMore({ variables: { cursor: endCursor } });
    }
  }, [hasNextPage, endCursor, fetchMore]);

  const handleClick = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleItemClick = useCallback(
    ({ id, status }: any) => {
      handleClose();
      if (status === NotificationStatus.Unread) {
        markNotificationAsRead({
          variables: {
            id,
          },
          optimisticResponse: {
            updateOneNotification: {
              id,
              status: NotificationStatus.Read,
            },
          },
          refetchQueries: [UnreadNotificationsCountDocument],
        });
      }
    },
    [markNotificationAsRead, handleClose],
  );

  return (
    <>
      {bottomNav ? (
        <BottomNavigationAction
          ref={ref}
          onClick={handleClick}
          aria-label="Notifications"
          {...otherProps}
          icon={
            <Badge badgeContent={count} color="error">
              {icon || <AlertSvg />}
            </Badge>
          }
        />
      ) : (
        <IconButton ref={ref} aria-label="Notifications" onClick={handleClick}>
          <Badge badgeContent={count} color="error">
            {icon || (
              <div
                onMouseEnter={() => setFillColor('#FDCC20')}
                onMouseLeave={() => setFillColor('#FFF3DC')}
              >
                <NotificationIcon color={fillColor} />
              </div>
            )}
          </Badge>
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={!!anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: bottomNav ? 'bottom' : 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: bottomNav ? 'top' : 'bottom' }}
        PaperProps={{
          component: MenuPaper,
        }}
        MenuListProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <MenuHeader hasUnread={!!count} />
        {nodes && nodes.length ? (
          nodes.map(entry => (
            <StyledLink
              key={entry.id}
              href={entry.link || '/'}
              target={entry.link && entry.link.indexOf('http') === 0 ? '_blank' : ''}
              onClick={() => handleItemClick(entry)}
            >
              <StyledMenuItem>
                <TextContainer>
                  <Title>{entry.title}</Title>
                  <Text variant="body2">{entry.text}</Text>
                </TextContainer>
                <DateContainer>
                  <DateBadge
                    color="secondary"
                    variant="dot"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    invisible={entry.status !== NotificationStatus.Unread}
                  >
                    {entry.createdAt && (
                      <DateText
                        variant="body2"
                        style={
                          entry.status === NotificationStatus.Unread ? { fontWeight: 700 } : {}
                        }
                      >
                        {toRelative(entry.createdAt)}
                      </DateText>
                    )}
                  </DateBadge>
                </DateContainer>
              </StyledMenuItem>
            </StyledLink>
          ))
        ) : (
          <StyledMenuItemCenter>
            <Typography align="center" variant="body2">
              You have no notifications
            </Typography>
          </StyledMenuItemCenter>
        )}
        {hasNextPage && (
          <StyledMenuItemCenter
            onClick={event => {
              event.preventDefault();
              handleLoadMore();
              return false;
            }}
          >
            <LoadMore>Load more</LoadMore>
          </StyledMenuItemCenter>
        )}
      </Menu>
    </>
  );
};

export const NotificationsMenu = forwardRef(NotificationsMenuWithRef);
