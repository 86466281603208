import { ImageProps } from 'next/image';

/**
 * Props to help with the transition from the custom image component to the next/image component
 */
export const nextImageTransitionProps: Partial<ImageProps> = {
  alt: '',
  width: 0,
  height: 0,
  sizes: '(max-width: 768px) 100vw, 800px',
  style: { width: '100%', height: '100%' },
};
