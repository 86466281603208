import {
  CreatedThreadDocument,
  CreatedThreadSubscription,
  UpdatedThreadDocument,
  UpdatedThreadSubscription,
  useCountUnreadThreadsQuery,
  useLatestThreadsQuery,
} from '@/generated/graphql';
import { useCurrentUser } from '@/hooks/use-current-user/use-current-user';
import styled from '@emotion/styled';
import {
  Badge,
  BottomNavigationAction,
  BottomNavigationActionProps,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/navigation';
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { theme } from '@/app/common/providers/theme-provider/theme';
import MobileChatIcon from '../../components/navbars/bottom-nav/svgs/chat.svg';
import ChatIcon from './components/chat-icon/chat-icon';
import ChatMenuItem from './components/chat-menu-item/chat-menu-item';
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;
const ThreadWrapper = styled(MenuItem)`
  border-radius: 10px;
  padding: 0 20px;
  :hover {
    opacity: 0.9;
  }
`;
const MenuPaper = styled(Paper)`
  width: 340px;
  border-radius: 20px;
  ${theme.breakpoints.up('sm')} {
    width: 400px;
  }
`;
const HeaderTitle = styled(Typography)`
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 127.3%;
  color: #231727;
`;
const UnreadCount = styled(Typography)`
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #767676;
`;
const SeeAllChats = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0 12px 0;
  border-top: 1px solid #cfcfcf;
  :hover {
    cursor: pointer;
  }
`;
const SeeAllChatsText = styled(Typography)`
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 136.8%;
  color: #231727;
`;
type ChatMenuProps = BottomNavigationActionProps & {
  bottomNav?: boolean;
  icon?: React.ReactNode;
};
export const ChatMenuWithRef = (
  { bottomNav, icon, ...rest }: ChatMenuProps,
  ref: ForwardedRef<HTMLButtonElement>,
): React.ReactElement => {
  const [fillColor, setFillColor] = useState<string>('#FFF3DC');
  const [anchorEl, setAnchorEl] = useState(null);
  const router = useRouter();
  const handleClick = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const { data } = useCurrentUser();
  const userId = data?.id;
  const {
    data: threads,
    subscribeToMore: getMoreThreads,
    refetch: refetchThreads,
  } = useLatestThreadsQuery({
    variables: {
      userId: userId!,
    },
    skip: !userId,
  });
  const threadList = threads?.messageThreads?.edges;
  const {
    data: unreadThreads,
    subscribeToMore: getMoreUnread,
    refetch: refetchUnread,
  } = useCountUnreadThreadsQuery({
    variables: {
      userId: userId!,
    },
    skip: !userId,
  });
  const numberOfUnread = unreadThreads?.threadParticipantsAggregate[0]?.count?.id || 0;
  useEffect(() => {
    if (!userId) {
      return;
    }
    getMoreThreads<CreatedThreadSubscription>({
      document: CreatedThreadDocument,
      updateQuery: (prev, { subscriptionData }) => {
        const createdThread = subscriptionData?.data?.createdMessageThread;
        if (!createdThread) {
          return prev;
        }
        return {
          ...prev,
          messageThreads: {
            ...prev?.messageThreads,
            edges: [
              ...prev.messageThreads.edges,
              {
                node: createdThread,
                cursor: null as any,
              },
            ],
          },
        };
      },
    });
    getMoreUnread<UpdatedThreadSubscription>({
      document: UpdatedThreadDocument,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedThread = subscriptionData?.data?.updatedOneMessageThread;
        if (updatedThread) {
          refetchUnread();
          refetchThreads();
          return prev;
        }
        return prev;
      },
    });
  }, [getMoreThreads, getMoreUnread, refetchThreads, refetchUnread, userId]);
  return (
    <>
      {bottomNav ? (
        <BottomNavigationAction
          onClick={() => {
            router.push('chat');
          }}
          aria-label={'Chat'}
          {...rest}
          icon={
            <Badge badgeContent={numberOfUnread} color="error">
              {icon || <MobileChatIcon />}
            </Badge>
          }
        />
      ) : (
        <IconButton ref={ref} aria-label="Chats" onClick={handleClick}>
          <Badge badgeContent={numberOfUnread} color="error">
            {
              <div
                onMouseEnter={() => setFillColor('#FDCC20')}
                onMouseLeave={() => setFillColor('#FFF3DC')}
              >
                <ChatIcon color={fillColor} />
              </div>
            }
          </Badge>
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={!!anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          component: MenuPaper,
        }}
        MenuListProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <Header>
          <HeaderTitle>Chats</HeaderTitle>
          <UnreadCount>{`${numberOfUnread} UNREAD`}</UnreadCount>
        </Header>
        {(threadList || [])?.map(thread => (
          <ThreadWrapper key={thread?.node?.id}>
            {/* TODO: this is probably wrong, but works */}
            <ChatMenuItem thread={thread?.node as any} handleClick={handleClose}></ChatMenuItem>
          </ThreadWrapper>
        ))}
        <SeeAllChats
          onClick={() => {
            handleClose();
            router.push('/chat');
          }}
        >
          <SeeAllChatsText>See all chats</SeeAllChatsText>
        </SeeAllChats>
      </Menu>
    </>
  );
};
export const ChatMenu = forwardRef(ChatMenuWithRef);
