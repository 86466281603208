import { Config } from './types';

export const stageConfig: Config = {
  baseUrl: 'https://www.curiko-staging.ca',
  apiEndpoint: process.env.NEXT_PUBLIC_API_ENDPOINT || 'https://api.curiko-staging.ca/graphql',
  apiWsEndpoint: process.env.NEXT_PUBLIC_API_WS_ENDPOINT || 'wss://api.curiko-staging.ca/graphql',
  segmentKey: 'Cm2xnXVmjpSsxGQmki7cLNu174sc8MOp',
  auth0: {
    redirectUri: 'https://www.curiko-staging.ca/auth/',
    domain: 'curiko-staging.us.auth0.com',
    clientId: 'Nip7zkmdXN53Rn5xkJWJl4Y5aLOyYnP8',
    audience: 'https://api.curiko-staging.ca',
    scope: 'read:current_user',
  },
  videoask: {
    moments: 'https://www.videoask.com/fshkjiarl',
    coaching: 'https://www.videoask.com/fshkjiarl',
    community: 'https://www.videoask.com/fshkjiarl',
    curator: 'https://www.videoask.com/fshkjiarl',
    contact: 'https://www.videoask.com/fshkjiarl',
    feedback: 'https://www.videoask.com/fshkjiarl',
    expFeedback: 'https://www.videoask.com/f8nst395k',
  },
  links: {
    coachesCorner:
      'https://www.curiko-staging.ca/experiences/9a02b36e-f4cf-4d8b-ac9b-025e7ba4ee3e/',
  },
  logRocketId: 'bruyyy/curiko',
  algoliaApplicationId: '6NM5PIJBR6',
  algoliaSearchKey: 'fb459f25cc397c3a918f9f6c946d0656',
  algoliaIndexPrefix: 'stage_',
  googleApiKey: 'AIzaSyD8ELnMJa8Wf5jMYRNbwAdWikr7U4kf97Q',
  calendly: {
    link: 'https://calendly.com/sarahk-99/host-welcome-sarahk',
    welcome: 'https://www.videoask.com/fymsp1t3m',
  },
  sanity: {
    projectId: 'x5d0gjiz',
    dataset: 'production',
  },
};
