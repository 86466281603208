import { useAtom } from 'jotai';
import { welcomeModalOpenAtom } from '../../atoms/welcome-modal-open/welcome-modal-open';

export function useWelcomeModalOpen() {
  const [welcomeModalOpen, setWelcomeModalOpen] = useAtom(welcomeModalOpenAtom);
  return {
    welcomeModalOpen,
    setWelcomeModalOpen,
  };
}
