import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { forwardRef, useCallback } from 'react';
import {
  NotificationsDocument,
  NotificationStatus,
  UnreadNotificationsCountDocument,
  useMarkAllNotificationsAsReadMutation,
} from '../../../../generated/graphql';
import { useCurrentUser } from '../../../../hooks/use-current-user/use-current-user';

const Container = styled.div`
  padding: 20px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`;

const Title = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 28px;
  color: #231727;
  font-weight: 700;
  line-height: 1;
`;

const StyledButton = styled(Button)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 12px;
  line-height: 1;
`;

type MenuHeaderProps = {
  hasUnread: boolean;
};

const MenuHeader = forwardRef<HTMLDivElement, MenuHeaderProps>(
  ({ hasUnread }, ref): React.ReactElement => {
    const { data: user } = useCurrentUser();
    const userId = user?.id;
    const [markAllNotificationsAsRead, { loading }] = useMarkAllNotificationsAsReadMutation();

    const handleClick = useCallback(() => {
      if (!userId || loading) {
        return;
      }
      markAllNotificationsAsRead({
        optimisticResponse: {
          updateManyNotifications: {
            updatedCount: 1,
          },
        },
        update: cache => {
          cache.writeQuery({
            query: UnreadNotificationsCountDocument,
            data: {
              notificationAggregate: [
                {
                  count: {
                    id: 0,
                  },
                },
              ],
            },
          });

          const { notifications: existing } = cache.readQuery({
            query: NotificationsDocument,
          }) as any;

          cache.writeQuery({
            query: NotificationsDocument,
            data: {
              notifications: {
                ...existing,
                edges: (existing.edges || []).map((edge: any) => ({
                  ...edge,
                  node: {
                    ...edge?.node,
                    status: NotificationStatus.Read,
                  },
                })),
              },
            },
          });
        },
      });
    }, [markAllNotificationsAsRead, userId, loading]);

    return (
      <Container ref={ref}>
        <Title>Notifications</Title>
        {hasUnread && (
          <StyledButton
            size="small"
            variant="text"
            onClick={handleClick}
            aria-label="Mark all notifications as read"
            disabled={loading}
          >
            Mark all as read
          </StyledButton>
        )}
      </Container>
    );
  },
);

MenuHeader.displayName = 'MenuHeader';

export { MenuHeader };
