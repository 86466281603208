import { pick } from 'lodash';
import { Source } from '@/generated/graphql';

interface ImageSource {
  src: string;
  width: number;
  height: number;
}

export const buildImageSource = (source?: Partial<Source>): ImageSource | null => {
  if (!source) {
    return null;
  }
  if (!source?.src) return null;
  if (!source?.width) return null;
  if (!source?.height) return null;

  return pick(source as ImageSource, ['src', 'width', 'height']);
};
