export const VIDEO_ASK_SHARE_BASE_URL = 'https://www.videoask.com';
export const VIDEO_ASK_API_BASE_URL = 'https://api.videoask.com';

export const VIDEO_ASK_EVENT_PREFIX = 'videoask_';
export enum VideoAskEventType {
  ContactCreated = 'videoask_contact_created',
  QuestionPresented = 'videoask_question_presented',
  QuestionSubmitted = 'videoask_question_submitted',
  ContactUpdated = 'videoask_contact_updated',
  Submitted = 'videoask_submitted',
}
