import Auth0Context, { Auth0ContextInterface } from './auth0-context';
import { ComponentType } from './types';

/**
 * Components wrapped in `withAuth0` will have an additional `auth0` prop
 */
export interface WithAuth0Props {
  auth0: Auth0ContextInterface;
}

/**
 * ```jsx
 * class MyComponent extends Component {
 *   render() {
 *     // Access the auth context from the `auth0` prop
 *     const { user } = this.props.auth0;
 *     return <div>Hello {user.name}!</div>
 *   }
 * }
 * // Wrap your class component in withAuth0
 * export default withAuth0(MyComponent);
 * ```
 *
 * Wrap your class components in this Higher Order Component to give them access to the Auth0Context
 */
const withAuth0 = <P extends WithAuth0Props>(
  Component: ComponentType<P & WithAuth0Props>,
): ComponentType<Omit<P, keyof WithAuth0Props>> => {
  const WithAuth = (props: Omit<P, keyof WithAuth0Props>): React.ReactElement => (
    <Auth0Context.Consumer>
      {(auth: Auth0ContextInterface): React.ReactElement => (
        <Component {...(props as P)} auth0={auth} />
      )}
    </Auth0Context.Consumer>
  );
  return WithAuth;
};

export default withAuth0;
