import React from 'react';

interface ChatIconProps {
  color: string;
}
const ChatIcon = ({ color }: ChatIconProps) => {
  return (
    <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.2874 16.0245C35.9289 10.9978 32.0924 6.11274 27.4948 3.45073C22.8973 0.788727 17.5072 0.300221 13.6833 0.484295C11.4697 0.622666 9.32449 1.39754 7.45266 2.73491C5.58082 4.07228 4.04481 5.9275 2.99131 8.1234C0.412652 13.3129 -0.442706 21.2989 2.29318 25.0654C5.02907 28.8318 11.3688 28.2867 17.0041 29.1362C17.7525 31.0832 17.6771 34.5735 15.9726 36.4497C17.8909 36.2373 20.589 35.947 22.5513 30.2265C25.891 30.9345 28.9036 31.3451 31.5326 29.8725C35.5138 27.6424 38.6396 20.9803 37.2874 16.0245ZM12.3751 25.4477C11.5014 25.4041 10.6314 25.2929 9.77128 25.1149C8.33924 24.8966 7.01202 24.1496 5.99764 22.991C3.70201 19.9891 4.05421 15.6138 4.89071 12.081C5.10542 11.1907 5.36796 10.3159 5.67688 9.46148C5.91043 8.66553 6.25661 7.91724 6.70205 7.2455C6.90874 6.96143 7.19465 6.76433 7.51338 6.6862C8.06685 6.58 7.84043 7.50038 7.79012 7.81897C7.3247 10.842 6.94105 14.028 7.84672 17.0227C8.45631 18.9122 9.41445 20.635 10.6581 22.0777C11.2253 22.7232 11.8237 23.3329 12.4506 23.9043C13.0795 24.499 14.5701 25.4618 14.5827 25.4901C14.5952 25.5185 12.7399 25.4831 12.3751 25.4477Z"
        fill={color}
      />
    </svg>
  );
};

export default ChatIcon;
