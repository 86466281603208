import { useAtom } from 'jotai';
import { onsiteTimerAtom } from '../../atoms/onsite-timer-atom/onsite-timer-atom';
import { useEffect } from 'react';

export function useOnsiteTimer() {
  const [onsiteTimer, setOnsiteTimer] = useAtom(onsiteTimerAtom);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOnsiteTimer(true);
    }, 45000);
    return () => clearTimeout(timer);
  }, [setOnsiteTimer]);

  return {
    onsiteTimer,
  };
}
