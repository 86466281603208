import React from 'react';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { Link } from '../link/link';
import { useVideoAskModal } from '../video-ask/lib/video-ask-client';
import { config } from '../../config/environment';
import { TextButton } from '../button/button';
import useHideFooterAtom from './hooks/use-hide-footer-atom';

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 12.5%;
  border-top: 2px solid;
  position: relative;
  z-index: 10;
  border-image: linear-gradient(to right, #231727, #4c3b51, #231727) 1;
  background-color: ${({ theme }) => theme.palette.common.midnight};
  & a {
    :hover {
      text-decoration: none;
    }
  }
`;
const LinksDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const BoldText = styled(Typography)`
  font-family: var(--font-work-sans);
  font-weight: 600;
  font-size: 16px;
  line-height: 136.8%;
  color: ${({ theme }) => theme.palette.common.cream};
  margin-bottom: 18px;
`;
const LinkText = styled(Typography)`
  font-family: var(--font-work-sans);
  font-size: 16px;
  line-height: 148%;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.palette.common.cream};
  :hover {
    color: ${({ theme }) => theme.palette.common.yellow};
    cursor: pointer;
  }
`;
const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const ContactIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  margin-bottom: 12px;
  object-fit: contain;
`;
const SocialIcon = styled.img`
  width: 37px;
  height: auto;
  margin-top: 8px;
  margin-right: 20px;
  :hover {
    cursor: pointer;
  }
`;
const SubmitButton = styled(Button)`
  width: max-content;
  height: 45px;
  background-color: transparent;
  padding: 13px 32px;
  border-radius: 20px;
  border: 2px solid #fff3dc;
  margin-left: 12px;
  color: #fff3dc;
  text-transform: none;
  font-family: var(--font-work-sans);
  font-weight: 600;
  font-size: 17px;
  line-height: 157.3%;
  :hover {
    background-color: #e5e5e5;
    color: ${({ theme }) => theme.palette.common.midnight};
  }
`;
const About = styled(Typography)`
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 148%;
  color: #bf9bb9;
  width: 100%;
  margin: 60px 0;
`;
const Sponsers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 60px 0;
`;
const TandCs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin: 0 12px;
`;
const SponserLogo = styled.img`
  width: 92px;
  height: auto;
`;
const SponserLogo2 = styled.img`
  width: 68px;
  height: auto;
`;
const SponserLogo3 = styled.img`
  width: 128px;
  height: auto;
`;
const SponserLogo4 = styled.img`
  width: 161px;
  height: auto;
`;
const Terms = styled(Typography)`
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  color: #e5e5e5;
  margin: 0 12px;
  :hover {
    color: ${({ theme }) => theme.palette.common.yellow};
    cursor: pointer;
  }
`;
const Star = styled.img`
  width: 13px;
  height: 15px;
  margin: 0 12px;
`;
const Footer = () => {
  const [openMomentsVideoAsk] = useVideoAskModal(config.videoask.moments);
  const [openContactVideoAsk] = useVideoAskModal(config.videoask.contact);
  const { hideFooter } = useHideFooterAtom();
  if (hideFooter) {
    return null;
  }
  return (
    <MainDiv>
      <LinksDiv>
        <LinksColumn>
          <BoldText>Curiko</BoldText>
          <Link href="/about/values/">
            <LinkText>Our values</LinkText>
          </Link>
          <Link href="/about/origins">
            <LinkText>Our origins</LinkText>
          </Link>

          <Link href="/about/theory-of-action">
            <LinkText>Our theory of action</LinkText>
          </Link>

          <Link href="/about/team">
            <LinkText>Our team</LinkText>
          </Link>
          <Link href="/discover/experiences">
            <LinkText>Why experiences?</LinkText>
          </Link>

          <Link href="/discover/coaching">
            <LinkText>Coaching</LinkText>
          </Link>
          <Link href="/faqs">
            <LinkText>FAQ</LinkText>
          </Link>
        </LinksColumn>
        <LinksColumn>
          <BoldText>Support Our Community</BoldText>
          <Link href="/discover/hosting">
            <LinkText>Host an Experience</LinkText>
          </Link>
          <Link href="/partners">
            <LinkText>Our partners and supporters</LinkText>
          </Link>
          <Link href="/partner-with-us">
            <LinkText>Partner with us</LinkText>
          </Link>
          <TextButton onClick={openMomentsVideoAsk}>
            <LinkText>Share a moment</LinkText>
          </TextButton>
          <TextButton onClick={openContactVideoAsk}>
            <LinkText>Tell us how we&apos;re doing</LinkText>
          </TextButton>
          <Link href="/careers">
            <LinkText>Careers</LinkText>
          </Link>
        </LinksColumn>
        <LinksColumn>
          <BoldText>Stay in Touch</BoldText>
          <ContactRow>
            <ContactIcon src={'/footer/phone.png'} alt="phone-icon" />
            <Link href="tel:+16048625836">
              <LinkText>(604) 862-5836</LinkText>
            </Link>
          </ContactRow>
          <ContactRow>
            <ContactIcon src={'/footer/email.png'} alt="phone-icon" />
            <Link href="mailto:hello@curiko.ca">
              <LinkText>hello@curiko.ca</LinkText>
            </Link>
          </ContactRow>
          <ContactRow>
            <Link href="https://www.instagram.com/curikoexperience/" target="_blank">
              <SocialIcon src={'/footer/insta.png'} alt={'instagram'} />
            </Link>
            <Link href="https://www.facebook.com/curikoexperience/" target="_blank">
              <SocialIcon src={'/footer/facebook.png'} alt={'facebook'} />
            </Link>
          </ContactRow>
        </LinksColumn>
        <LinksColumn>
          <ContactRow>
            <Link href={'http://eepurl.com/gk9gRz'}>
              <SubmitButton>Sign up for our newsletter!</SubmitButton>
            </Link>
          </ContactRow>
        </LinksColumn>
      </LinksDiv>
      <About>
        Curiko is situated on the traditional and stolen lands of the hən̓qəmin̓əm̓ and Sḵwx̱wú7mesh
        speaking peoples. We acknowledge the harms of colonization, and seek to learn from and carry
        forward Indigenous stories of resistance and healing. We commit to co-creating a community
        where every human is valued and belongs.
      </About>
      <Sponsers>
        <ImageDiv>
          <SponserLogo src={'/footer/inWithForward.png'} alt={'inWithForward logo'} />
        </ImageDiv>
        <ImageDiv>
          <SponserLogo2 src={'/footer/baci.png'} alt={'baci logo'} />
        </ImageDiv>
        <ImageDiv>
          <SponserLogo3 src={'/footer/kinsight.png'} alt={'kinsight logo'} />
        </ImageDiv>
        <ImageDiv>
          <SponserLogo4 src={'/footer/posibilities.png'} alt={'posibilities logo'} />
        </ImageDiv>
      </Sponsers>
      <TandCs>
        <Link href="/terms">
          <Terms>TERMS & CONDITIONS</Terms>
        </Link>
        <Star src={'/footer/star.png'} alt="star" />
        <Link href="/privacy">
          <Terms>PRIVACY POLICY</Terms>
        </Link>
        <Star src={'/footer/star.png'} alt="star" />
        <Link href="/cookies">
          <Terms>COOKIE POLICY</Terms>
        </Link>
      </TandCs>
    </MainDiv>
  );
};

export default Footer;
