import styled from '@emotion/styled';
import { ChatMenu } from '../../../chat-menu/chat-menu';
import { NotificationsMenu } from '../../../notifications-menu/notifications-menu';
import { UserMenu } from '../../../user-menu/user-menu';

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

const UserItem = styled.div`
  position: relative;
  left: 10px;
`;

const AuthenticatedMenu = () => {
  return (
    <Container>
      <NotificationsMenu />
      <ChatMenu />
      <UserItem>
        <UserMenu />
      </UserItem>
    </Container>
  );
};

export { AuthenticatedMenu };
