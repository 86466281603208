import { atom, useAtom } from 'jotai';

const hideFooterAtom = atom<boolean>(false);
const useHideFooterAtom = () => {
  const [hideFooter, setHideFooter] = useAtom(hideFooterAtom);
  return {
    hideFooter,
    setHideFooter,
  };
};
export default useHideFooterAtom;
