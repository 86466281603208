import { devConfig } from './dev';
import { prodConfig } from './prod';
import { stageConfig } from './stage';
import { Config, Environment } from './types';

const configMap: Record<string, Config> = {
  [Environment.prod]: prodConfig,
  [Environment.stage]: stageConfig,
  [Environment.dev]: devConfig,
};

export const env: string = process.env['NEXT_PUBLIC_ENV'] || 'dev';

export const config: Config = configMap[env];
