import YellowArrowSvg from './svgs/yellow-arrow.svg';
import CloseIconSvg from './svgs/close-icon.svg';
import CloseIconSvgWhite from './svgs/close-icon-white.svg';
export const YellowArrow = () => {
  return <YellowArrowSvg />;
};
export const CloseIcon = () => {
  return <CloseIconSvg />;
};
export const CloseIconWhite = () => {
  return <CloseIconSvgWhite />;
};
