type RecursivelyReplaceNullWithUndefined<T> = T extends null
  ? undefined // Note: Add interfaces here of all GraphQL scalars that will be transformed into an object
  : T extends Date
    ? T
    : {
        [K in keyof T]: T[K] extends (infer U)[]
          ? RecursivelyReplaceNullWithUndefined<U>[]
          : RecursivelyReplaceNullWithUndefined<T[K]>;
      };

/**
 * Recursively replaces all nulls with undefineds.
 * Skips object classes (that have a `.__proto__.constructor`).
 *
 * Unfortunately, until https://github.com/apollographql/apollo-client/issues/2412
 * gets solved at some point,
 * this is the only workaround to prevent `null`s going into the codebase,
 * if it's connected to a Apollo server/client.
 */
export function nullToUndefined<T>(obj: T): RecursivelyReplaceNullWithUndefined<T> {
  if (!obj) {
    return undefined as RecursivelyReplaceNullWithUndefined<T>;
  }
  const newObj: any = {};
  Object.keys(obj || {}).forEach(k => {
    const v: any = (obj as any)[k];
    newObj[k as keyof T] =
      v === null
        ? undefined
        : // eslint-disable-next-line no-proto
          v && typeof v === 'object' && v.__proto__.constructor === Object
          ? nullToUndefined(v)
          : v;
  });
  return newObj;
}
