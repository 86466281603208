import { theme } from '@/app/common/providers/theme-provider/theme';
import styled from '@emotion/styled';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  BottomNavigationAction,
  BottomNavigationActionProps,
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  PaperProps,
  PopoverPaper,
  Typography,
} from '@mui/material';
import React, { forwardRef, useCallback, useState } from 'react';
import { useAuth0 } from '../../auth';
import { useCurrentUser } from '../../hooks/use-current-user/use-current-user';
import { Link } from '../link/link';
import { Avatar } from './components/avatar/avatar';
import { UserMenuHeader } from './components/user-menu-header/user-menu-header';
import ArrowSvg from './svgs/arrow.svg';
import MyBookingsSvg from './svgs/my-bookings.svg';
import StarsSvg from './svgs/stars.svg';

const AvatarContainer = styled.span`
  display: block;
  width: 46px;
  height: 46px;
`;

const TextContainer = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 16px;
  color: #231727;
  font-weight: 700;
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
  width: 100%;
  display: block;
`;

const MenuItemButton = styled(ButtonBase)`
  text-overflow: ellipsis;
  font-family: var(--font-work-sans), sans-serif;
  font-size: 16px;
  color: #231727;
  font-weight: normal;
  display: block;
  width: 100%;
`;

const MenuItemIcon = styled.span`
  color: black;
  width: 38px;
  height: 38px;
  background-color: #c4c4c4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  margin-left: 18px;

  svg {
    width: 70%;
    height: 70%;
  }
`;

const MenuItemInner = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 35px;
`;

const MenuPaper = styled(PopoverPaper)`
  width: 340px;
  border-radius: 20px;
  ${theme.breakpoints.up('sm')} {
    width: 400px;
  }
`;

const ArrowContainer = styled.span`
  width: 20px;
  height: 20px;
  margin-left: auto;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export type StyledMenuItemProps = {
  href?: string;
  onClick?: () => void;
  icon?: React.ReactNode | React.ReactNode[] | string;
  children?: React.ReactNode | React.ReactNode[] | string;
};

export const StyledMenuItem = ({
  onClick,
  href,
  icon,
  children,
}: StyledMenuItemProps): React.ReactElement => {
  const LinkComponent = href ? StyledLink : MenuItemButton;
  return (
    <LinkComponent href={href!} onClick={onClick}>
      <MenuItemInner>
        {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
        <TextContainer>{children}</TextContainer>
        <ArrowContainer>
          <ArrowSvg />
        </ArrowContainer>
      </MenuItemInner>
    </LinkComponent>
  );
};

StyledMenuItem.displayName = 'StyledMenuItem';

type UserMenuProps = BottomNavigationActionProps & {
  bottomNav?: boolean;
};

function UserMenuWithRef(
  { bottomNav, ...otherProps }: UserMenuProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
): React.ReactElement {
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const { data: user } = useCurrentUser();
  const profile = user?.profile;

  const handleLogout = useCallback(() => {
    logout({
      returnTo: window.location.origin,
    });
  }, [logout]);

  const handleOpen = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      {bottomNav ? (
        <BottomNavigationAction
          ref={ref}
          onClick={handleOpen}
          aria-label="User menu"
          {...otherProps}
          icon={
            <AvatarContainer style={{ width: '32px', height: '32px' }}>
              <Avatar profile={profile} />
            </AvatarContainer>
          }
        />
      ) : (
        <IconButton ref={ref} onClick={handleOpen} aria-label="User menu">
          <AvatarContainer>
            <Avatar bg profile={profile} />
          </AvatarContainer>
        </IconButton>
      )}

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={!!anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: bottomNav ? 'bottom' : 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: bottomNav ? 'top' : 'bottom' }}
        slots={{
          paper: MenuPaper,
        }}
        MenuListProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <MenuItem>
          <UserMenuHeader user={user} onClick={handleClose} />
        </MenuItem>
        <MenuItem>
          <StyledMenuItem href="/my-experiences/" onClick={handleClose} icon={<StarsSvg />}>
            Hosting dashboard
          </StyledMenuItem>
        </MenuItem>
        <MenuItem>
          <StyledMenuItem href="/bookings/" onClick={handleClose} icon={<MyBookingsSvg />}>
            My bookings
          </StyledMenuItem>
        </MenuItem>
        <MenuItem>
          <StyledMenuItem href="/settings/" onClick={handleClose} icon={<SettingsIcon />}>
            Settings
          </StyledMenuItem>
        </MenuItem>
        {!bottomNav && (
          <MenuItem>
            <StyledMenuItem onClick={handleLogout} icon={<ExitToAppIcon />}>
              Log out
            </StyledMenuItem>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export const UserMenu = forwardRef(UserMenuWithRef);
