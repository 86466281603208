import { useMediaQuery } from '@mui/material';

const useIsMobile = (optionalSize?: number) => {
  const size = optionalSize ? optionalSize : 960;
  const isMobile = useMediaQuery(`(max-width:${size}px)`);
  return {
    isMobile,
  };
};
export default useIsMobile;
